import { BaseSchema, ModelType, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type WasteDataRelationships = {
    wasteCenter: ToOneRelationship<ModelType.WASTE_CENTER>;
    invoice: ToOneRelationship<ModelType.INVOICES>;
};

type WasteDataAttributes = {
    wasteType: string;
    cost: number;
    estimatedVolumeHigh: number;
    estimatedVolumeLow: number;
};

type WasteDataMeta = {};

export default class WasteDataSchema extends BaseSchema<WasteDataAttributes, WasteDataMeta, WasteDataRelationships> {
    protected type: ModelType = ModelType.WASTE_DATAS;
}
