<script setup lang="ts">
    import WasteCenter from '@/app/common/models/WasteCenter';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import InvoiceForm from '~~/app/invoicing/classes/InvoiceForm';
    import WasteDataSchema from '~/app/invoicing/schemas/WasteDataSchema';

    const props = defineProps({
  invoiceForm: null
});

    const form = useForm<{
        wasteType: string;
        wasteCenter: WasteCenter | null;
        estimatedVolumeLow: number | null;
        estimatedVolumeHigh: number | null;
        cost: number | null;
    }>({
        wasteType: '',
        wasteCenter: null,
        estimatedVolumeHigh: null,
        estimatedVolumeLow: null,
        cost: null,
    });

    useModal('invoicing:invoice-form:waste-data', (data: { form: InvoiceForm }) => {
        form.reset();
        const wasteData = data.form.get('wasteDataCollection');
        if (wasteData) {
            form.fillWithModel(wasteData);
        }
    });

    const reset = () => {
        props.invoiceForm.set('wasteDataCollection', null);
        useEvent('invoicing:invoice-form:waste-data:close');
    };

    const submit = async () => {
        const wasteData = props.invoiceForm.get('wasteDataCollection');
        const wasteDataId = (wasteData && wasteData.getId()) || undefined;
        const endpoint = EndpointFactory.make(ModelType.WASTE_DATAS);
        const schema = WasteDataSchema.make({
            attributes: form.dataExcept(['wasteCenter']),
            id: wasteDataId,
        });
        schema.addToOneRelationship('wasteCenter', ModelType.WASTE_CENTER, form.get('wasteCenter').getId());
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));

        props.invoiceForm.set('wasteDataCollection', response.data);
        useEvent('invoicing:invoice-form:waste-data:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice-form:waste-data"
    >
        <div>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{ $t('invoicing.invoice_form.edit_waste_data_modal.title') }}
                </h2>
            </div>
            <form @submit.prevent="submit">
                <div :class="$theme('modal.padding')">
                    <!--  IN PROGRESS -->
                    <FormWasteCenterSelect
                        :form="form"
                        input-name="wasteCenter"
                        :with-create-button="true"
                    />
                    <FormWasteTypeSelect
                        :form="form"
                        with-null-option
                        input-name="wasteType"
                    />
                    <FormTwoFields>
                        <template #field-1>
                            <FormInput
                                type="number"
                                :form="form"
                                input-type="number"
                                input-name="estimatedVolumeLow"
                                :min="0"
                                :step="0.01"
                            />
                        </template>
                        <template #field-2>
                            <FormInput
                                type="number"
                                input-type="number"
                                :form="form"
                                input-name="estimatedVolumeHigh"
                                :min="0"
                                :step="0.01"
                            />
                        </template>
                    </FormTwoFields>
                    <FormPrice
                        :form="form"
                        input-name="cost"
                    />
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :class-name="$theme('button.style.white')"
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            @clicked="reset"
                        >
                            {{ $t('actions.reset') }}
                        </LoadingButton>
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                        >
                            {{ $t('actions.save') }}
                        </LoadingButton>
                    </div>
                </div>
            </form>
        </div>
    </Modal>
</template>
